import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    List,
    Edit,
    Create,
    FunctionField,
    required,
    SimpleForm,
    TextInput,
    Datagrid,
    EmailField,
    TextField,
    DateField,
    Show,
    TopToolbar,
    ShowButton,
    Filter,
    TabbedShowLayout,
    Tab,
    SimpleList,
    TabbedShowLayoutTabs,
    useLocale,
    ReferenceField,
    BooleanInput,
    Toolbar,
    useNotify,
    useRedirect,
} from "react-admin";
import dayjs from "dayjs";
import { BooleanField, ListButton } from "ra-ui-materialui";
import { useMediaQuery } from "@material-ui/core";
import { Cancel, CheckCircle, ChevronLeft, Add } from "@material-ui/icons";
import {
    Button,
    Select,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Menu,
    ListItem,
    Paper,
    MenuList,
    ListItemText,
    ListItemIcon,
    Typography,
} from "@mui/material";
import { gql, useQuery, useMutation } from "@apollo/client";
import UserTable from "../components/UserTable";
import UserLoginBarGraph from "../components/UserLoginBarGraph";
import TimeUserSpentPieGraph from "../components/TimeUserSpentPieGraph";
import AssetsUsagePieGraph from "../components/AssetsUsagePieGraph";
import { ACCESS_LEVEL } from "./User";
import { PersonAdd } from "@mui/icons-material";
import InviteUserModal from "../components/InviteUserModal";

const MUTATION_INSERT_ORGANISATION = gql`
    mutation AddOrganisation(
        $name: String!
        $short_name: String!
        $slack_issue_notifications: Boolean!
        $timezone: String!
        $updated: timestamptz!
        $created: timestamptz!
    ) {
        insert_organisation_one(
            object: {
                name: $name
                short_name: $short_name
                slack_issue_notifications: $slack_issue_notifications
                timezone: $timezone
                updated: $updated
                created: $created
                use_benchmarker_domain: false
            }
        ) {
            id
            name
        }
    }
`;

const MUTATION_INSERT_SUBSCRIPTION = gql`
    mutation addSubscription(
        $subscriptions: [organisation_subscription_insert_input!] = {}
    ) {
        insert_organisation_subscription(objects: $subscriptions) {
            returning {
                id
                organisation_id
                service
            }
        }
    }
`;

const QUERY_ORG_SUBSCRIPTIONS = gql`
    query getAllSubscriptions {
        organisation_subscription(distinct_on: service) {
            id
            service
        }
    }
`;

const QUERY_ORG_USER_METRICS = gql`
    query getOrganisationsUserMetrics($orgId: Int!, $monthAgo: timestamptz!) {
        ppcs_core_userloginhistory(
            where: {
                user: { organisation_id: { _eq: $orgId } }
                trigger_source: { _eq: "AUTH" }
                application: { _neq: "ADMIN" }
                time: { _gte: $monthAgo }
            }
            order_by: { time: desc }
        ) {
            id
            time
            application
            user {
                id
                email
            }
        }
        assets: ppcs_core_usersessionhistory(
            where: {
                user: { organisation_id: { _eq: $orgId } }
                time: { _gte: $monthAgo }
                application: { _eq: "ASSETS" }
            }
        ) {
            id
            time
            url
            application
            browser
            device
        }
        app: ppcs_core_usersessionhistory(
            where: {
                user: { organisation_id: { _eq: $orgId } }
                time: { _gte: $monthAgo }
                application: { _eq: "APP" }
            }
        ) {
            id
            time
            url
            application
            browser
            device
        }
        shop: ppcs_core_usersessionhistory(
            where: {
                user: { organisation_id: { _eq: $orgId } }
                time: { _gte: $monthAgo }
                application: { _eq: "SHOP" }
            }
        ) {
            id
            time
            url
            application
            browser
            device
        }
    }
`;

const QUERY_ORGANISATION_AGGREGATE_VALUES = gql`
    query organisation($organisation: Int!) {
        organisation_by_pk(id: $organisation) {
            id
            sites_aggregate {
                aggregate {
                    count
                }
            }
            assets_aggregate {
                aggregate {
                    count
                }
            }
            issues_aggregate(where: { status: { _lt: 9 } }) {
                aggregate {
                    count
                }
            }
            prompts_aggregate(where: { status: { _lt: 9 } }) {
                aggregate {
                    count
                }
            }
            requests_aggregate(where: { open: { _eq: true } }) {
                aggregate {
                    count
                }
            }
            users(order_by: { last_login: desc_nulls_last }, limit: 1) {
                id
                email
                last_login
                login_history(order_by: { time: desc_nulls_last }) {
                    id
                    application
                    time
                    trigger_source
                }
            }
        }
    }
`;

const OrganisationShowAside = ({ record }) => {

    const inputEl = useRef(null);
    const [inviteUserModal, setInviteUserModal] = useState(false)

    return (
        <Paper sx={{ width: '20%', marginX: 2, padding: 2 }}>
            <InviteUserModal organisation={record} open={inviteUserModal} handleClose={() => setInviteUserModal(false)} />

            <Typography variant="h5" gutterBottom component="div">
                Actions
            </Typography>
            <MenuList dense
                autoFocus={false}
                open
                anchorEl={inputEl.current}
            >
                <MenuItem onClick={() => setInviteUserModal(true)}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    <ListItemText inset>
                        Invite User
                    </ListItemText>
                </MenuItem>
            </MenuList>
        </Paper>


    );
};

export const OrganisationCreate = (props) => {
    const [formData, setFormdata] = useState({
        orgName: "",
        shortName: "",
    });
    const [subscriptionOptions, setSubscriptionOptions] = useState();
    const [selectedSubsriptions, setSelectedSubscriptions] = useState([]);
    const [enableSlack, setEnableSlack] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const [addOrganisation] = useMutation(MUTATION_INSERT_ORGANISATION);
    const [addSubscription] = useMutation(MUTATION_INSERT_SUBSCRIPTION);

    const { data: orgSubs, error: orgSubsError } = useQuery(
        QUERY_ORG_SUBSCRIPTIONS,
        {
            onError: () => console.log(orgSubsError),
        }
    );

    useEffect(() => {
        if (!orgSubs) return;
        let services = [];
        orgSubs.organisation_subscription.map((sub) => {
            return services.push({
                value: sub.id,
                label: sub.service,
            });
        });
        setSubscriptionOptions(services);
    }, [orgSubs]);

    const PostEditToolbar = () => (
        <Toolbar>
            <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleSubmit}
                disabled={formData.orgName === "" || formData.shortName === ""}
            >
                ADD ORGANISATION
            </Button>
        </Toolbar>
    );

    const handleInputChange = (e) => {
        e.persist();
        setFormdata((inputs) => ({
            ...inputs,
            [e.target.id]: e.target.value,
        }));
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedSubscriptions(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    const handleSubmit = useCallback(() => {
        let services = [];
        selectedSubsriptions.map((sub) => {
            return services.push({
                service: sub,
            });
        });
        addOrganisation({
            variables: {
                name: formData.orgName,
                short_name: formData.shortName,
                slack_issue_notifications: enableSlack,
                created: dayjs().format(),
                updated: dayjs().format(),
                timezone: "Pacific/Auckland",
            },
        })
            .then((r) => {
                let orgId = r.data.insert_organisation_one.id;
                let subscriptionObjects = [];
                if (selectedSubsriptions.length > 0) {
                    selectedSubsriptions.map((service) => {
                        return subscriptionObjects.push({
                            organisation_id: orgId,
                            service: service,
                        });
                    });
                    addSubscription({
                        variables: {
                            subscriptions: subscriptionObjects,
                        },
                    })
                        .then((r) => {
                            console.log(r);
                        })
                        .catch((e) => {
                            console.log(e);
                            notify(
                                `Error adding organisation subscription ${e}`
                            );
                        });
                }
                notify(
                    `Organisation: ${r.data.insert_organisation_one.name} added`
                );
                redirect("/organisation");
            })
            .catch((e) => {
                notify(`Error adding organisation: ${e}`);
                console.log(e);
            });
    }, [
        addOrganisation,
        enableSlack,
        formData,
        notify,
        redirect,
        addSubscription,
        selectedSubsriptions,
    ]);

    return (
        <Create {...props}>
            <SimpleForm toolbar={<PostEditToolbar />}>
                <TextInput
                    fullWidth
                    source="name"
                    id="orgName"
                    validate={[required()]}
                    onChange={handleInputChange}
                />
                <TextInput
                    onChange={handleInputChange}
                    fullWidth
                    id="shortName"
                    source="short_name"
                    validate={[required()]}
                />
                {subscriptionOptions && (
                    <div>
                        <InputLabel id="demo-multiple-name-label">
                            Organisation Subscription
                        </InputLabel>
                        <Select
                            sx={{ width: 300, marginBottom: "1rem" }}
                            multiple
                            value={selectedSubsriptions}
                            onChange={handleChange}
                            input={
                                <OutlinedInput label="Organisation Subscriptions" />
                            }
                        >
                            {subscriptionOptions.map((sub) => (
                                <MenuItem key={sub.value} value={sub.label}>
                                    {sub.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                )}
                <TextInput fullWidth source="logo_image_filename" />
                <BooleanInput
                    label="Enable Slack Issue Notifications"
                    source="slack_issue_notifications"
                    defaultValue={false}
                    onChange={(e) => setEnableSlack(e)}
                />
            </SimpleForm>
        </Create>
    );
};

const OrganisationEditActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);

const OrganisationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search name" source="name" alwaysOn />
    </Filter>
);

const SubscriptionList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: -16, marginLeft: -16, marginRight: -16 }}
            {...props}
            perPage={100}
            basePath={"/organisation_subscription"}
            resource={"organisation_subscription"}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.service}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"service"} />
                </Datagrid>
            )}
        </List>
    );
};

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by email" source="email" alwaysOn />
    </Filter>
);

const UserList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: -16, marginLeft: -16, marginRight: -16 }}
            {...props}
            resource={"user_profile"}
            basePath={"/user_profile"}
            perPage={100}
            filters={<UserFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.email}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <EmailField source="email" />
                    <TextField source={"first_name"} />
                    <TextField source={"last_name"} />
                    <TextField source={"username"} />
                    <DateField locales={locale} source={"last_login"} />
                    <BooleanField source={"active"} />
                </Datagrid>
            )}
        </List>
    );
};

const SiteFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search name" source="name" alwaysOn />
    </Filter>
);

const SiteList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: -16, marginLeft: -16, marginRight: -16 }}
            {...props}
            perPage={100}
            basePath={"/site"}
            resource={"site"}
            sort={{ field: "name", order: "DESC" }}
            filters={<SiteFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"name"} />
                    <TextField source={"short_name"} />
                    <ReferenceField source="address_id" reference="address">
                        <TextField source="street" />
                    </ReferenceField>
                    <DateField locales={locale} source={"updated"} />
                </Datagrid>
            )}
        </List>
    );
};

export const AlertRosterList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const AlertRosterFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search by roster name" source="name" alwaysOn />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -16, marginLeft: -16, marginRight: -16 }}
            {...props}
            perPage={100}
            basePath={"/alert_roster"}
            resource={"alert_roster"}
            sort={{ field: "name", order: "DESC" }}
            filters={<AlertRosterFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => `${record.id}`}
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"name"} />
                    <TextField source="service" />
                    <DateField source="start_time" />
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                </Datagrid>
            )}
        </List>
    );
};

const UserGroupList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const GroupFilter = (props) => (
        <Filter {...props}>
            <TextInput
                label="Search by user-group name"
                source="name"
                alwaysOn
            />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -16, marginLeft: -16, marginRight: -16 }}
            {...props}
            perPage={100}
            resource={"ppcs_core_usergroup"}
            basePath={"/ppcs_core_usergroup"}
            sort={{ field: "name", order: "DESC" }}
            filters={<GroupFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => `${record.id}`}
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"name"} />
                </Datagrid>
            )}
        </List>
    );
};

const PolicyList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const PolicyFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search by policy name" source="name" alwaysOn />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -16, marginLeft: -16, marginRight: -16 }}
            {...props}
            perPage={100}
            sort={{ field: "name", order: "DESC" }}
            basePath={"/ppcs_core_policy"}
            resource={"ppcs_core_policy"}
            filters={<PolicyFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => `${record.id}`}
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"name"} />
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        link={"show"}
                        source="owner_id"
                        reference="organisation"
                    >
                        <TextField
                            source="name"
                            render={(record) => ACCESS_LEVEL}
                        />
                    </ReferenceField>
                    <FunctionField
                        label="Role"
                        render={(record) => ACCESS_LEVEL[record["role"]]}
                    />
                </Datagrid>
            )}
        </List>
    );
};

const DeviceList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const DeviceFilter = (props) => (
        <Filter {...props}>
            <TextInput
                label="Search by serial number"
                source="serial_number"
                alwaysOn
            />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -16, marginLeft: -16, marginRight: -16 }}
            {...props}
            basePath={"/sab_device"}
            resource={"sab_device"}
            perPage={100}
            sort={{ field: "serial_number", order: "DESC" }}
            filters={<DeviceFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.serial_number}
                    secondaryText={(record) => `${record.id}`}
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"serial_number"} />
                    <TextField source="dev_eui" />
                    <DateField source="created" />
                    <DateField source="updated" />
                </Datagrid>
            )}
        </List>
    );
};

const LastOrgUserLogin = (props) => {
    const organisationId = props.record.id;

    const [login, setLogin] = useState();

    const { data } = useQuery(QUERY_ORGANISATION_AGGREGATE_VALUES, {
        variables: {
            organisation: organisationId,
        },
    });

    useEffect(() => {
        if (!data) return;
        if (data.organisation_by_pk.users.length > 0) {
            let user = data.organisation_by_pk.users[0];
            if (user.login_history.length > 0) {
                let l = user.login_history[0];
                setLogin(
                    user.email +
                    " at " +
                    dayjs(l.time).format("D MMMM h:mm:ss a")
                );
            }
        }
    }, [data]);

    return <div>{login}</div>;
};

const NumSitesField = (props) => {
    const organisationId = props.record.id;
    const [sites, setSites] = useState(0);

    const { data, error } = useQuery(QUERY_ORGANISATION_AGGREGATE_VALUES, {
        variables: {
            organisation: organisationId,
        },
    });
    useEffect(() => {
        if (!data) return;
        setSites(data.organisation_by_pk.sites_aggregate.aggregate.count);
    }, [data, error]);
    return <div>{sites}</div>;
};

const NumAssetsField = (props) => {
    const organisationId = props.record.id;
    const [assets, setAssets] = useState(0);

    const { data, error } = useQuery(QUERY_ORGANISATION_AGGREGATE_VALUES, {
        variables: {
            organisation: organisationId,
        },
    });
    useEffect(() => {
        if (!data) return;
        setAssets(data.organisation_by_pk.assets_aggregate.aggregate.count);
    }, [data, error]);
    return <div>{assets}</div>;
};

const NumIssuesField = (props) => {
    const organisationId = props.record.id;
    const [issues, setIssues] = useState(0);

    const { data, error } = useQuery(QUERY_ORGANISATION_AGGREGATE_VALUES, {
        variables: {
            organisation: organisationId,
        },
    });
    useEffect(() => {
        if (!data) return;
        setIssues(data.organisation_by_pk.issues_aggregate.aggregate.count);
    }, [data, error]);
    return <div>{issues}</div>;
};

const NumPromptsField = (props) => {
    const organisationId = props.record.id;
    const [prompts, setPrompts] = useState(0);

    const { data, error } = useQuery(QUERY_ORGANISATION_AGGREGATE_VALUES, {
        variables: {
            organisation: organisationId,
        },
    });
    useEffect(() => {
        if (!data) return;
        setPrompts(data.organisation_by_pk.prompts_aggregate.aggregate.count);
    }, [data, error]);
    return <div>{prompts}</div>;
};

const NumRequestsField = (props) => {
    const organisationId = props.record.id;
    const [requests, setRequests] = useState(0);

    const { data, error } = useQuery(QUERY_ORGANISATION_AGGREGATE_VALUES, {
        variables: {
            organisation: organisationId,
        },
    });
    useEffect(() => {
        if (!data) return;
        setRequests(data.organisation_by_pk.requests_aggregate.aggregate.count);
    }, [data, error]);
    return <div>{requests}</div>;
};

export const OrganisationList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={100}
            sort={{ field: "name", order: "DESC" }}
            filters={<OrganisationFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(organisation) => organisation.name}
                    secondaryText={(organisation) => `${organisation.id}`}
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"name"} />
                    <TextField source="short_name" />
                    <FunctionField
                        label="Sites"
                        render={(record) => <NumSitesField record={record} />}
                    />
                    <FunctionField
                        label="Assets"
                        render={(record) => <NumAssetsField record={record} />}
                    />
                    <FunctionField
                        label="Open Issues"
                        render={(record) => <NumIssuesField record={record} />}
                    />
                    <FunctionField
                        label="Open Prompts"
                        render={(record) => <NumPromptsField record={record} />}
                    />
                    <FunctionField
                        label="Open Requests"
                        render={(record) => (
                            <NumRequestsField record={record} />
                        )}
                    />
                    <FunctionField
                        label="Last User Login"
                        render={(record) => (
                            <LastOrgUserLogin record={record} />
                        )}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export const OrganisationEdit = (props) => (
    <Edit {...props} actions={<OrganisationEditActions />}>
        <SimpleForm>
            <TextInput fullWidth source="name" />
            <TextInput fullWidth source="short_name" />
            <TextInput fullWidth source="logo_image_filename" />
            <TextInput fullWidth source="theme" />
        </SimpleForm>
    </Edit>
);

export const OrganisationShow = (props) => {
    const [monthAgo] = useState(dayjs().subtract(31, "days"));
    const [metricsData, setMetricsData] = useState();
    const { data: orgMetricsData, error: orgMetricsError } = useQuery(
        QUERY_ORG_USER_METRICS,
        {
            variables: {
                orgId: props.id,
                monthAgo: monthAgo,
            },
            onError: () => console.log(orgMetricsError),
        }
    );

    useEffect(() => {
        if (!orgMetricsData) return;
        setMetricsData(orgMetricsData);
    }, [orgMetricsData]);
    return (
        <Show {...props} aside={<OrganisationShowAside />}>
            <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
            >
                <Tab label="summary">
                    <TextField source="name" />
                    <TextField source="short_name" />
                    <TextField source="logo_image_filename" />
                    <TextField source="theme" />
                    <EmailField source="super_admin" />
                    <BooleanField source="slack_issue_notifications" />
                </Tab>
                <Tab label="sites">
                    <SiteList
                        sort={{ field: "name", order: "ASC" }}
                        filter={{ organisation_id: props.id }}
                    />
                </Tab>
                <Tab label="users">
                    <UserList
                        sort={{ field: "email", order: "DESC" }}
                        filter={{ organisation_id: props.id }}
                    />
                </Tab>
                <Tab label="User Metrics">
                    {metricsData && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                height: "48rem",
                                overflowY: "auto",
                                overflowX: "auto",
                            }}
                        >
                            <UserTable
                                data={metricsData.ppcs_core_userloginhistory}
                                hasUser
                                width="35%"
                            />
                            <div style={{ width: "60%" }}>
                                <UserLoginBarGraph
                                    data={
                                        metricsData.ppcs_core_userloginhistory
                                    }
                                    width="100%"
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "4rem",
                                    }}
                                >
                                    <TimeUserSpentPieGraph
                                        data={metricsData}
                                        width="60%"
                                    />
                                    <AssetsUsagePieGraph
                                        data={metricsData}
                                        width="60%"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </Tab>
                <Tab label="subscriptions">
                    <SubscriptionList
                        sort={{ field: "service", order: "DESC" }}
                        filter={{ organisation_id: props.id }}
                    />
                </Tab>
                <Tab label="user groups">
                    <UserGroupList
                        sort={{ field: "name", order: "DESC" }}
                        filter={{ organisation_id: props.id }}
                    />
                </Tab>
                <Tab label="alert rosters">
                    <AlertRosterList
                        sort={{ field: "name", order: "DESC" }}
                        filter={{ organisation_id: props.id }}
                    />
                </Tab>
                <Tab label="policies">
                    <PolicyList
                        sort={{ field: "name", order: "DESC" }}
                        filter={{ organisation_id: props.id }}
                    />
                </Tab>
                <Tab label="devices">
                    <DeviceList
                        sort={{ field: "serial_number", order: "DESC" }}
                        filter={{ organisation_id: props.id }}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
