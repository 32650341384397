import React, { useState, useEffect, useCallback } from "react";
import {
    List,
    Edit,
    SimpleForm,
    TextInput,
    Datagrid,
    EmailField,
    TextField,
    DateField,
    useLocale,
    TopToolbar,
    ShowButton,
    Filter,
    ReferenceField,
    SimpleList,
    Show,
    Tab,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
    useNotify,
} from "react-admin";

import {
    BooleanField,
    DateTimeInput,
    ListButton,
    ReferenceInput,
    AutocompleteInput,
} from "ra-ui-materialui";
import dayjs from "dayjs";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useMediaQuery } from "@material-ui/core";
import { Cancel, CheckCircle, Delete, Save } from "@material-ui/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Button, TextField as MTextField, Autocomplete } from "@mui/material";
import UserTable from "../components/UserTable";
import UserLoginBarGraph from "../components/UserLoginBarGraph";
import TimeUserSpentPieGraph from "../components/TimeUserSpentPieGraph";
import AssetsUsagePieGraph from "../components/AssetsUsagePieGraph";

const QUERY_USER_POLICIES = gql`
    query getUserPolicies($userId: Int!) {
        user_profile_by_pk(id: $userId) {
            id
            policies {
                id
                policy {
                    id
                    name
                    role
                }
            }
        }
    }
`;

const QUERY_ALL_POLICIES = gql`
    query getAllPolicies($userId: Int!) {
        ppcs_core_policy(
            where: { _not: { users: { userprofile_id: { _eq: $userId } } } }
        ) {
            id
            name
            organisation {
                id
                name
            }
        }
    }
`;

const QUERY_LOGIN_HISTORY_BY_USER = gql`
    query getLoginHistory($userId: Int!, $monthAgo: timestamptz!) {
        user_profile_by_pk(id: $userId) {
            login_history(
                order_by: { time: desc }
                where: {
                    application: { _neq: "ADMIN" }
                    time: { _gte: $monthAgo }
                }
            ) {
                id
                time
                trigger_source
                application
                user {
                    id
                }
            }
            id
            email
            assets: session_history(
                where: {
                    application: { _eq: "ASSETS" }
                    time: { _gte: $monthAgo }
                }
            ) {
                id
                application
                time
                url
                device
                browser
                application
            }
            app: session_history(
                where: {
                    application: { _eq: "APP" }
                    time: { _gte: $monthAgo }
                }
            ) {
                id
                time
                application
                url
                device
                browser
                application
            }
            shop: session_history(
                where: {
                    application: { _eq: "SHOP" }
                    time: { _gte: $monthAgo }
                }
            ) {
                id
                time
                url
                application
                device
                browser
                application
            }
        }
    }
`;

const MUTATION_DELETE_POLICY_USER = gql`
    mutation deletePolicyUsers($policyId: Int!, $userId: Int!) {
        delete_ppcs_core_policy_users(
            where: {
                policy_id: { _eq: $policyId }
                userprofile_id: { _eq: $userId }
            }
        ) {
            returning {
                id
                policy_id
            }
        }
    }
`;

const MUTATION_INSERT_POLICY_USER = gql`
    mutation insertPolicyUser($policyId: Int!, $userId: Int!) {
        insert_ppcs_core_policy_users_one(
            object: { policy_id: $policyId, userprofile_id: $userId }
        ) {
            policy_id
            userprofile_id
        }
    }
`;

export const ACCESS_LEVEL = {
    0: "VIEW",
    1: "EDIT",
    2: "ADMIN",
};
const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by email" source="email" alwaysOn />
    </Filter>
);

const UserEditActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);

export const UserList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={100}
            filters={<UserFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.id}
                    secondaryText={(record) =>
                        `${record.first_name} ${record.last_name}`
                    }
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <EmailField source="email" />
                    <TextField source={"first_name"} />
                    <TextField source={"last_name"} />
                    <TextField source={"username"} />
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <DateField showTime locales={locale} source={"last_login"} />
                    <BooleanField source={"active"} />
                </Datagrid>
            )}
        </List>
    );
};

export const UserEdit = (props) => {
    const locale = useLocale();

    return (
        <Edit {...props} actions={<UserEditActions />}>
            <SimpleForm>
                <TextInput fullWidth source="email" />
                <TextInput fullWidth source="first_name" />
                <TextInput fullWidth source="last_name" />
                <DateTimeInput locales={locale} source={"last_login"} />
                <ReferenceInput
                    source="organisation_id"
                    reference="organisation"
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const UserShow = (props) => {
    const notify = useNotify();
    const locale = useLocale();
    const [monthAgo] = useState(dayjs().subtract(31, "days"));

    const [policies, setPolicies] = useState();
    const [policyOptions, setPolicyOptions] = useState();
    const [selectedPolicy, setSelectedPolicy] = useState();
    const [removePolicyRelationship] = useMutation(MUTATION_DELETE_POLICY_USER);
    const [addPolicyRelationship] = useMutation(MUTATION_INSERT_POLICY_USER);
    const [userData, setUserData] = useState();

    const {
        data: policyData,
        error: policyError,
        refetch: refetchUserPolicies,
    } = useQuery(QUERY_USER_POLICIES, {
        variables: {
            userId: props.id,
        },
        onError: () => console.log(policyError),
    });

    const { data: loginData, error: loginDataError } = useQuery(
        QUERY_LOGIN_HISTORY_BY_USER,
        {
            variables: {
                userId: props.id,
                monthAgo: monthAgo,
            },
            onError: () => console.log(loginDataError),
        }
    );

    const {
        data: allPolicies,
        error: allPoliciesError,
        refetch: refetchAllPolicies,
    } = useQuery(QUERY_ALL_POLICIES, {
        variables: {
            userId: props.id,
        },
        onError: () => console.log(allPoliciesError),
    });

    useEffect(() => {
        if (!loginData || !loginData.user_profile_by_pk) return;
        setUserData(loginData.user_profile_by_pk);
    }, [loginData]);

    useEffect(() => {
        if (!allPolicies) return;
        let policies = [];
        allPolicies.ppcs_core_policy.map((policy) => {
            return policies.push({
                value: policy.id,
                label: policy.name,
            });
        });
        setPolicyOptions(policies);
    }, [allPolicies]);

    useEffect(() => {
        if (!policyData) return;
        let policies = [];
        policyData.user_profile_by_pk.policies.map((policy) => {
            return policies.push(policy.policy);
        });
        setPolicies(policies);
    }, [policyData]);

    const handleSave = useCallback(
        (policy) => {
            addPolicyRelationship({
                variables: {
                    policyId: selectedPolicy.value,
                    userId: props.id,
                },
            })
                .then((r) => {
                    notify(`policy assigned`);
                    refetchUserPolicies();
                    refetchAllPolicies();
                })
                .catch((e) => {
                    notify(`Error assigning policy: ${e}`);
                });
        },
        [
            addPolicyRelationship,
            notify,
            props,
            refetchUserPolicies,
            refetchAllPolicies,
            selectedPolicy,
        ]
    );

    const handleRemove = useCallback(
        (policy) => {
            removePolicyRelationship({
                variables: {
                    policyId: policy.id,
                    userId: props.id,
                },
            })
                .then((r) => {
                    refetchUserPolicies();
                    notify(`Policy: ${policy.name} has been removed from user`);
                })
                .catch((e) => {
                    notify(`Error removing policy: ${e}`);
                });
        },
        [notify, props, removePolicyRelationship, refetchUserPolicies]
    );

    return (
        <Show {...props}>
            <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
            >
                <Tab label="summary">
                    <EmailField fullWidth source="email" />
                    <TextField fullWidth source="first_name" />
                    <TextField fullWidth source="last_name" />
                    <TextField fullWidth source="username" />

                    <DateField locales={locale} source={"last_login"} />
                    <BooleanField source={"active"} />
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                </Tab>
                <Tab label="User Policies">
                    {policyOptions && (
                        <div>
                            <div>ASSIGN POLICY TO USER</div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <Autocomplete
                                    onChange={(e, v) => setSelectedPolicy(v)}
                                    sx={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                        width: "30rem",
                                    }}
                                    options={policyOptions}
                                    renderInput={(params) => (
                                        <MTextField
                                            {...params}
                                            label="Policies by organisation"
                                        />
                                    )}
                                />
                                <Button
                                    onClick={handleSave}
                                    disabled={!selectedPolicy}
                                    sx={{
                                        height: "100%",
                                        alignSelf: "center",
                                        marginLeft: "1rem",
                                    }}
                                    variant="contained"
                                    startIcon={<Save />}
                                >
                                    SAVE
                                </Button>
                            </div>
                        </div>
                    )}
                    <div>USER'S EXISTING POLICIES:</div>
                    {policies &&
                        policies.map((policy) => {
                            return (
                                <div
                                    style={{
                                        paddingTop: "1.5rem",
                                        paddingBottom: "0.5rem",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <MTextField
                                        sx={{ paddingRight: "1rem" }}
                                        label="Policy name"
                                        disabled
                                        id="name"
                                        defaultValue={policy.name}
                                    />
                                    <Autocomplete
                                        id="role"
                                        disabled
                                        sx={{
                                            paddingBottom: 2,
                                            width: "16rem",
                                        }}
                                        options={["VIEW", "EDIT", "ADMIN"]}
                                        defaultValue={ACCESS_LEVEL[policy.role]}
                                        renderInput={(params) => (
                                            <MTextField
                                                {...params}
                                                label="Access Level"
                                            />
                                        )}
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: "100%",
                                            marginLeft: "1rem",
                                            alignSelf: "start",
                                            marginTop: "0.5rem",
                                        }}
                                        startIcon={<Delete />}
                                        onClick={() => handleRemove(policy)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            );
                        })}
                </Tab>
                <Tab label="User Details">
                    {userData && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                height: "48rem",
                                overflowY: "auto",
                                overflowX: "auto",
                            }}
                        >
                            <UserTable
                                data={userData.login_history}
                                width="35%"
                            />
                            <div style={{ width: "60%" }}>
                                <UserLoginBarGraph
                                    data={userData.login_history}
                                    width="100%"
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "4rem",
                                    }}
                                >
                                    <TimeUserSpentPieGraph
                                        data={userData}
                                        width="60%"
                                    />
                                    <AssetsUsagePieGraph
                                        data={userData}
                                        width="60%"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
