import * as React from "react";
import {
    List,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    SimpleList,
    useLocale,
    ReferenceField,
    FunctionField,
} from "react-admin";

import { useMediaQuery } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";

export const PolicyList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const renderRole = (role) => {
        console.log(role);
        let roles = {
            0: "View",
            1: "Edit",
            2: "Admin",
        };
        return roles[role];
    };

    const PolicyFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search by policy name" source="name" alwaysOn />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={100}
            sort={{ field: "name", order: "DESC" }}
            filters={<PolicyFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => `${record.id}`}
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        link={"show"}
                        source="owner_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source={"name"} />
                    <FunctionField
                        source="role"
                        render={(record) => renderRole(record.role)}
                    />
                </Datagrid>
            )}
        </List>
    );
};
