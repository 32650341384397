import * as React from "react";
import {
    List,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    SimpleList,
    useLocale,
    ReferenceField,
} from "react-admin";

import { useMediaQuery } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";

export const AddressList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const AddressFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search by street" source="street" alwaysOn />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={100}
            sort={{ field: "street", order: "DESC" }}
            filters={<AddressFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.street}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"city"} />
                    <TextField source="code" />
                    <TextField source="latitude" />
                    <TextField source="longitude" />
                    <TextField source="province" />
                    <TextField source="street" />
                    <TextField source="suburb" />
                    {/* <ReferenceField
                        link={"show"}
                        source="site_id"
                        reference="site"
                    >
                        <TextField source="name" />
                    </ReferenceField> */}
                </Datagrid>
            )}
        </List>
    );
};
