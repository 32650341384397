import * as React from "react";
import {
    List,
    TextInput,
    Create,
    SimpleForm,
    ReferenceInput,
    Datagrid,
    TextField,
    AutocompleteInput,
    DateField,
    Filter,
    SimpleList,
    useLocale,
    ReferenceField,
} from "react-admin";
import { gql, useQuery } from "@apollo/client";

import { useMediaQuery } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { Rectangle } from "@mui/icons-material";

export const DeviceCreate = (props) => {
    // const [getDevice, { data: deviceId, error: deviceError }] = useLazyQuery(
    //     QUERY_DEVICE_ID_FROM_MP,
    //     {
    //         onError: () => console.log(deviceError),
    //     }
    // );

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput fullWidth source="serial_number" />
                <TextInput fullWidth source="dev_eui" />
                <TextInput fullWidth source="description" />
                <ReferenceInput
                    source="organisation_id"
                    reference="organisation"
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const DeviceList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const DeviceFilter = (props) => (
        <Filter {...props}>
            <TextInput
                label="Search by serial number"
                source="serial_number"
                alwaysOn
            />
        </Filter>
    );


    return (
        <List

            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={100}
            pagination={false}
            sort={{ field: "organisation_id", order: "DESC" }}
            filters={<DeviceFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.serial_number}
                    secondaryText={(record) => `${record.id}`}
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source={"serial_number"} />
                    <DateField showTime source="last_packet_time" />

                    <TextField source="dev_eui" />
                    <DateField source="created" />
                    <DateField source="updated" />

                </Datagrid>
            )}
        </List>
    );
};
