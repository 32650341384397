import React, { useState, useEffect } from "react";
import {
    List,
    Create,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    SimpleList,
    useLocale,
    ReferenceField,
    Tab,
    Show,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
    DateField,
} from "react-admin";
import { TextField as MTextField, Autocomplete } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";
import AddMeasurementPoint from "../components/AddMeasurementPoint";

const QUERY_ORGS = gql`
    query allOrgs {
        organisation {
            id
            name
            sites {
                id
                name
                zones {
                    id
                    name
                }
            }
        }
    }
`;

export const MeasurementPointCreate = (props) => {
    const [orgOptions, setOrgOptions] = useState();
    const [selectedOrg, setSelectedOrg] = useState();
    const [siteOptions, setSiteOptions] = useState();
    const [filteredSites, setFilteredSites] = useState();
    const [selectedSite, setSelectedSite] = useState();
    const [zoneOptions, setZoneOptions] = useState();
    const [filteredZones, setFilteredZones] = useState();
    const [selectedZone, setSelectedZone] = useState();
    const { data: allOrgs, error: orgError } = useQuery(QUERY_ORGS, {
        onError: () => console.log(orgError),
    });

    useEffect(() => {
        if (!allOrgs) return;
        let orgs = [];
        let sites = [];
        let zones = [];
        allOrgs.organisation.map((org) => {
            org.sites.map((site) => {
                site.zones.map((zone) => {
                    return zones.push({
                        label: zone.name,
                        id: zone.id,
                        siteId: site.id,
                    });
                });
                return sites.push({
                    label: site.name,
                    id: site.id,
                    orgId: org.id,
                });
            });
            return orgs.push({
                label: org.name,
                id: org.id,
            });
        });
        setOrgOptions(orgs);
        setSiteOptions(sites);
        setZoneOptions(zones);
    }, [allOrgs]);

    useEffect(() => {
        if (selectedOrg) {
            let filteredSites = siteOptions.filter(
                (site) => site.orgId === selectedOrg.id
            );
            setFilteredSites(filteredSites);
        } else {
            setFilteredSites();
        }
    }, [selectedOrg, siteOptions]);

    useEffect(() => {
        if (selectedSite) {
            let filteredZones = zoneOptions.filter(
                (zone) => zone.siteId === selectedSite.id
            );
            setFilteredZones(filteredZones);
        } else {
            setFilteredZones();
        }
    }, [selectedSite, zoneOptions]);

    return (
        <Create {...props}>
            <div style={{ margin: "2rem" }}>
                {orgOptions && (
                    <Autocomplete
                        fullWidth
                        id="org"
                        sx={{ paddingBottom: 2 }}
                        options={orgOptions}
                        onChange={(e, v) => setSelectedOrg(v)}
                        renderInput={(params) => (
                            <MTextField {...params} label="Organisation" />
                        )}
                    />
                )}
                {siteOptions && (
                    <Autocomplete
                        fullWidth
                        disabled={!filteredSites}
                        id="site"
                        sx={{ paddingBottom: 2 }}
                        options={filteredSites}
                        onChange={(e, v) => setSelectedSite(v)}
                        renderInput={(params) => (
                            <MTextField {...params} label="Site" />
                        )}
                    />
                )}
                {zoneOptions && (
                    <Autocomplete
                        fullWidth
                        id="zone"
                        disabled={!filteredZones}
                        sx={{ paddingBottom: 2 }}
                        options={filteredZones}
                        onChange={(e, v) => setSelectedZone(v)}
                        renderInput={(params) => (
                            <MTextField {...params} label="Zone" />
                        )}
                    />
                )}
                <AddMeasurementPoint
                    orgId={selectedOrg ? selectedOrg.id : null}
                    siteId={selectedSite ? selectedSite.id : null}
                    zone={selectedZone ? selectedZone.id : null}
                />
            </div>
        </Create>
    );
};

export const MeasurementPointShow = (props) => {
    return (
        <Show {...props}>
            <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
            >
                <Tab label="summary">
                    <TextField source="name" />
                    <ReferenceField
                        link={"show"}
                        source="zone_id"
                        reference="zone"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        link={"show"}
                        source="device_id"
                        reference="sab_device"
                    >
                        <TextField source="serial_number" />
                    </ReferenceField>
                    <TextField source="device_type" />
                    <TextField source="latitude" />
                    <TextField source="longitude" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};


export const MeasurementPointList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const MeasurementPointFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search by Name" source="name" alwaysOn />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={100}
            sort={{ field: "name", order: "ASC" }}
            filters={<MeasurementPointFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => `${record.id}`}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <ReferenceField label="Organisation"
                        link={"show"}
                        source="zone_id"
                        reference="zone"
                    >
                        <ReferenceField
                            link={"show"}
                            source="site_id"
                            reference="site"
                        >
                            <ReferenceField
                                link={"show"}
                                source="organisation_id"
                                reference="organisation"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </ReferenceField>
                    </ReferenceField>
                    <TextField source={"name"} />
                    <ReferenceField
                        link={"show"}
                        source="zone_id"
                        reference="zone"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Site"
                        link={"show"}
                        source="zone_id"
                        reference="zone"
                    >
                        <ReferenceField
                            link={"show"}
                            source="site_id"
                            reference="site"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </ReferenceField>
                    <ReferenceField
                        link={"show"}
                        source="device_id"
                        reference="sab_device"
                    >
                        <TextField source="serial_number" />
                    </ReferenceField>
                    <ReferenceField
                        label={"Last packet time"}
                        link={"show"}
                        source="device_id"
                        reference="sab_device"
                    >
                        <DateField showTime source="last_packet_time" />
                    </ReferenceField>
                    <TextField source="device_type" />
                </Datagrid>
            )}
        </List>
    );
};
